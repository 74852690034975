<template>
  <div class='py-1 px-2 block w-full font-medium'>
    {{ showingValue }}
  </div>
</template>

<script>
import DateTimeStringHelpers from '@/utils/date-time-string-helpers'

export default {
  name: 'EditFormFieldReadOnly',
  props: [
    'value',
    'metaData'
  ],
  computed: {
    showingTitlesByValue () {
      return this.metaData && this.metaData.showingTitlesByValue ? this.metaData.showingTitlesByValue : []
    },
    isArray () {
      return this.metaData && this.metaData.isArray
    },
    isDatetime () {
      return this.metaData && this.metaData.isDatetime
    },
    showingValue () {
      if (this.isArray) {
        return this.value.join(', ')
      } else if (this.isDatetime) {
        return DateTimeStringHelpers.toDateTime(this.value)
      } {
        let index = this.showingTitlesByValue.findIndex(showingTitleByValue => showingTitleByValue.value === this.value)
        if (index > -1) {
          return this.showingTitlesByValue[index].title
        } else {
          return this.value
        }
      }
    }
  }
}
</script>
